import React, { useEffect, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import scrollBanner from "../../assets/images/scroll-banner.jpg";

const ScrollBanner = () => {
  const initializeAOS = useCallback(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    initializeAOS();
  }, [initializeAOS]);

  return (
    <div className="scroll-banner-outer">
      <img
        src={scrollBanner}
        alt="Banner, der auf der Website durch Scrollen sichtbar wird"
        data-aos="fade-up"
        className="scroll-banner-img"
        srcSet={`${scrollBanner} 1x, ${scrollBanner}?w=1000 2x`}
      />
    </div>
  );
};

export default ScrollBanner;
