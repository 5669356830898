import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AOS from "aos";
import "aos/dist/aos.css";
import { useCallback, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";

const WelcomeSection = () => {
  const motivationSectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const scrollToMotivationSection = useCallback(() => {
    if (motivationSectionRef.current) {
      motivationSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="welcome-section-wrapper" data-aos="fade-up">
      <div className="welcome-section" data-aos="fade-up">
        <h1>Herzlich Willkommen bei</h1>
        <span className="logo-text">Moon Restaurant</span>
        <p className="motto-text">"Sushi & Bar"</p>
        <hr className="spacer" />
        <div className="button-wrapper">
          <Button
            variant="outline-light"
            className="mr-3"
            href={`${process.env.PUBLIC_URL}/MITTAGSKARTE.pdf`}
            target="_blank"
            aria-label="Mittagskarte anzeigen"
          >
            Mittagskarte <RestaurantMenuIcon />
          </Button>
          <Button
            variant="outline-light"
            href={`${process.env.PUBLIC_URL}/ABENDKARTE.pdf`}
            target="_blank"
            aria-label="Abendkarte anzeigen"
          >
            Abendkarte <RestaurantMenuIcon />
          </Button>
        </div>
      </div>
      <div
        className="arrow-icon-wrapper"
        onClick={scrollToMotivationSection}
        role="button"
        aria-label="Zu den nächsten Informationen scrollen"
      >
        <KeyboardArrowDownIcon sx={{ fontSize: 50, color: "white" }} />
      </div>
      <div ref={motivationSectionRef} className="about-us-section"></div>
    </div>
  );
};

export default WelcomeSection;
