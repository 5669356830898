import React, { useCallback, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

// Importiere Bilder stattdessen direkt
import food1 from "../../assets/images/food-1.jpg";
import food2 from "../../assets/images/food-2.jpg";
import food3 from "../../assets/images/food-3.jpg";
import food4 from "../../assets/images/food-4.jpg";
import food5 from "../../assets/images/food-5.jpg";
import food6 from "../../assets/images/food-6.jpg";
import food7 from "../../assets/images/food-7.jpg";
import food8 from "../../assets/images/food-8.jpg";
import food9 from "../../assets/images/food-9.jpg";
import food10 from "../../assets/images/food-10.jpg";

const images = [
  { src: food1, alt: "Gericht 1" },
  { src: food2, alt: "Gericht 2" },
  { src: food3, alt: "Gericht 3" },
  { src: food4, alt: "Gericht 4" },
  { src: food5, alt: "Gericht 5" },
  { src: food6, alt: "Gericht 6" },
  { src: food7, alt: "Gericht 7" },
  { src: food8, alt: "Gericht 8" },
  { src: food9, alt: "Gericht 9" },
  { src: food10, alt: "Gericht 10" },
];

const GallerySection = () => {
  const initializeAOS = useCallback(() => {
    AOS.init({
      duration: 300,
      easing: "ease-in-out",
    });
  }, []);

  useEffect(() => {
    initializeAOS();
  }, [initializeAOS]);

  return (
    <>
      <h2 className="section-title" data-aos="fade-up">
        Galerie
      </h2>
      <div className="gallery-section-outer">
        <div className="gallery-section">
          {images.map((image, index) => (
            <div
              className="gallery-image"
              key={image.alt}
              data-aos="fade-left"
              data-aos-delay={`${index * 200}`}
              data-aos-offset="200"
            >
              <img
                src={image.src}
                alt={image.alt}
                srcSet={`${image.src} 1x, ${image.src}?w=1000 2x`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GallerySection;
