import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useCallback, useEffect, useRef, useState } from "react";
import ContactSection from "../components/ContactSection/ContactSection";
import Footer from "../components/Footer/footer";
import GallerySection from "../components/GallerySection/gallerySection";
import MotivationSection from "../components/MotivationSection/MotivationSection";
import NewsBanner from "../components/NewsBanner/NewsBanner";
import ScrollBanner from "../components/ScrollBanner/Scrollbanner";
import WelcomeSection from "../components/WelcomeSection/welcomeSection";

function Home() {
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const welcomeSectionRef = useRef<HTMLDivElement | null>(null);

  const initializeAOS = useCallback(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    initializeAOS();

    const handleScroll = () => {
      if (welcomeSectionRef.current) {
        const { top } = welcomeSectionRef.current.getBoundingClientRect();
        setScrollTopVisible(top < 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [initializeAOS]);

  const handleScrollTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="App">
      <div
        className="homepage-banner"
        id="welcome-section"
        ref={welcomeSectionRef}
      >
        <WelcomeSection />
      </div>
      <section className="about-us-section">
        <MotivationSection />
      </section>
      <section id="photo-section" className="gallery-section">
        <GallerySection />
      </section>
      <ScrollBanner />
      <div className="contact-section">
        <ContactSection />
      </div>
      <NewsBanner />
      <div className="footer-section">
        <Footer />
      </div>
      {scrollTopVisible && (
        <div
          className="scroll-top-button"
          onClick={handleScrollTop}
          role="button"
          aria-label="Nach oben scrollen"
        >
          <KeyboardArrowUpIcon />
        </div>
      )}
    </div>
  );
}

export default Home;
