import React, { useEffect } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // Initialisierung von AOS
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease",
      mirror: false,
    });
  }, []);

  // Social media links als Konfiguration für einfachere Erweiterung
  const socialLinks = [
    {
      icon: <FacebookIcon />,
      href: "https://www.facebook.com/profile.php?id=100092262050294",
      label: "Facebook",
    },
  ];

  return (
    <footer className="footer-section-outer">
      <div className="footer-section-inner">
        <div className="footer-copyright">Copyright © {currentYear}</div>
        <div className="footer-brand" data-aos="fade-right">
          <span className="footer-restaurant-name">Moon</span>
        </div>

        <hr className="footer-divider" />
        <div className="footer-social-icons">
          {socialLinks.map((social) => (
            <div key={social.href} className="social-icon">
              <a
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Besuche uns auf ${social.label}`}
              >
                {social.icon}
              </a>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
