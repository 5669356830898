import React, { useCallback } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { constants } from "../../common/constants";

const ContactSection = () => {
  // Verwende useCallback, um Funktionen nur dann neu zu erstellen, wenn es notwendig ist
  const openLink = useCallback((url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  }, []);

  const openPhoneLink = useCallback((phoneNumber: string) => {
    const cleanPhoneNumber = phoneNumber.replace(/\D/g, "");
    window.open(`tel:${cleanPhoneNumber}`);
  }, []);

  const openEmailLink = useCallback(() => {
    const email = constants.EMAIL;
    window.location.href = `mailto:${encodeURIComponent(email)}`;
  }, []);

  const openMapLink = useCallback(() => {
    const address = "Dekan-Wagner-Straße 22, 84032 Altdorf";
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address
      )}`
    );
  }, []);

  return (
    <div
      className="contact-section-outer"
      data-aos="zoom-in-up"
      id="contact-section"
    >
      <h2 className="section-title" data-aos="fade-up">
        Kontakt
      </h2>
      <div className="contact-section-inner">
        <div className="contact-text" data-aos="fade-right">
          <p>
            Wir als Restaurant <span className="restaurant-name">Moon</span>{" "}
            legen großen Wert auf Kundenzufriedenheit und sind stets für Ihre
            Anliegen und Wünsche da. <br /> <br /> Sie können uns per E-Mail
            oder Telefon kontaktieren, um Fragen, Anregungen oder Feedback
            mitzuteilen. <br /> <br /> Besuchen Sie uns gerne persönlich an
            unserem Standort, da wir kontinuierlich bestrebt sind, unseren
            Service und unsere Angebote zu verbessern. <br /> <br /> Wir freuen
            uns auf Ihre Anfragen und darauf, Sie im Restaurant{" "}
            <span className="restaurant-name">Moon</span> begrüßen zu dürfen!
          </p>
        </div>
        <div className="information-icons">
          <div className="contact-icons">
            {[
              {
                icon: <LocalPhoneIcon />,
                title: "Telefon",
                description: constants.PHONE_PRIMARY,
                onClick: () => openPhoneLink(constants.PHONE_PRIMARY),
              },
              {
                icon: <EmailIcon />,
                title: "Email",
                description: constants.EMAIL,
                onClick: openEmailLink,
              },
              {
                icon: <LocationOnIcon />,
                title: "Adresse",
                description: "Dekan-Wagner-Straße 22, 84032 Altdorf",
                onClick: openMapLink,
              },
              {
                icon: <FacebookIcon />,
                title: "Folge uns",
                description: "Besuche unsere Facebook-Seite",
                onClick: () =>
                  openLink(
                    "https://www.facebook.com/people/MO-PHO-Restaurant/100092262050294/"
                  ),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="contact-icon"
                data-aos="fade-left"
                onClick={item.onClick}
              >
                <div className="icon">{item.icon}</div>
                <div className="text">
                  <div className="title">{item.title}</div>
                  <div className="description">{item.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
